/**
 * Entire page
 */
body {
    overflow-y: scroll;
    overflow-x: hidden;
}
body, html {
    height: 100%;
}

/**
 * Page cover
 */
#cover { 
    /* 
    background: url(../img/landingpage_optimized.jpg) no-repeat top left rgb(1,2,2);
    background-attachment: fixed;
    */
    background: url(../img/landingpage_dna.jpg) no-repeat top left rgb(1,2,2);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    
    /*
    @include media-query($on-palm) {
    */
    @media all and (max-aspect-ratio: 5/3) {
        background: url(../img/landingpage_dna.jpg) no-repeat top center rgb(1,2,2);
        -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        -o-background-size: auto 100%;
        background-size: auto 100%;
    }
    
    
    /*
     *-webkit-background-size: cover;
     *-moz-background-size: cover;
     *-o-background-size: cover;
     *background-size: cover;
     */
}

#headline {
    background: url(../img/headline_optimized.png) no-repeat top center; 
    -webkit-background-size: 20%;
    -moz-background-size: 20%;
    -o-background-size: 20%;
    background-size: 20%;
    height: 100%; 
}

#headline-empty {
    height: 100%;
}


/* about block */

#about-block{
    background: url(../img/grey_texture.jpg) no-repeat top center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /* background: #20212c; */
    /* background: #15161d; */
    /* background: #000000; */
    color: #ffffff;
    a:link{color:#ffef99};
    a:visited{color:#ffef99}
    /* a:link{color:#2a7ae2}; */ /* This is light blue */
    /* a:visited{color:#2a7ae2} */ /*  This is light blue */
    /* mouse over link         */
    a:hover{color:$grey-color-light}
}


#container-affiliation {
    width:100%;
    background: rgba(250,250,250,0.7);
    /*
    border: 2px solid rgb(220,220,220);
    padding: 25px;
    margin: 25px;
    */
    font-weight:normal;
    text-align:center;
    align-content: center;
    text-justify:newspaper;
    text-kashida-space:50%;
    /* margin-bottom: 20px; */
    display:block;
    vertical-align:top;
    position: relative;
    float: center;
    /*
    */
}

#container-whiteBox {
    width:100%;
    background: rgba(256,256,256,1);
    /*
    border: 2px solid rgb(220,220,220);
    padding: 25px;
    margin: 25px;
    */
    font-weight:normal;
    text-align:center;
    align-content: center;
    text-justify:newspaper;
    text-kashida-space:50%;
    /* margin-bottom: 20px; */
    display:block;
    vertical-align:top;
    position: relative;
    float: center;
    /*
    */
}

#container-greyBox {
    width:100%;
    background: url(../img/shadow.png) no-repeat top center; 
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
    /*
    background: rgba(256,256,256,1);
    border: 2px solid rgb(220,220,220);
    padding: 25px;
    margin: 25px;
    */
    font-weight:normal;
    text-align:center;
    align-content: center;
    text-justify:newspaper;
    text-kashida-space:50%;
    /* margin-bottom: 20px; */
    display:block;
    vertical-align:top;
    position: relative;
    float: center;
    /*
    */
}

#about-extension{
    /*
    background: rgba(224,170,15,0.5);
    */
    
    /*
    background: url(../img/utaustin_greyMedium.jpg) no-repeat top right;
    background: url(../img/utaPlanetarium.png) no-repeat top right;
    */
    background: url(../img/utaSeirSouthMedium.jpg) no-repeat top right;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #000000;
    a:link{color:#2a7ae2};   /* This is light blue */
    a:visited{color:#2a7ae2} /*  This is light blue */
    /* mouse over link         */
    a:hover{color:#CC5500}   /*  This is UT burnt Orange color (PMS 159 U PMS 166 C Burnt Orange) */
}

/**
 * People page
 */
#people { 
    background: url(../img/people/ICES_TGM_group_optimized.jpg) no-repeat left center rgb(1,2,2); 
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    
    @media all and (max-aspect-ratio: 640/457) {
        background: url(../img/people/ICES_TGM_group_optimized.jpg) no-repeat left center rgb(1,2,2);
        -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        -o-background-size: auto 100%;
        background-size: auto 100%;
    }
    
}


.people-title {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    color: $text-color-extra-light;
    text-shadow: 2px 2px 4px #000000;

    &,
    &:visited {
        color: $grey-color-light;
    }
}

.people-icon {
    display: inline-block;
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
    margin: 0px;
    margin-top: 2px;
    background: $text-color-light;
    color: $brand-color-dark;
    border-radius: 2px;

    &:hover {
        color: $brand-color;
    }

    .icon-tooltip {
        display: none;
        position: absolute;
        top: 26px;
        left: 0px;
        background-color: $text-color-extra-light;
        color: $grey-color-dark;
        border-color: $grey-color-dark;
        border-style: solid;
        border-width: 0.5px;
        border-radius: 2px;
        width: auto;
        height: 1.3*$base-font-size;
        padding: 1px;
        padding-left: 2px;
        padding-right: 2px;
        z-index: 10000;
    }
    &:hover .icon-tooltip {
        display: block;
    }

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $brand-color;
        }
    }
}


/***
 * Loader image
 */
.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* background: url('../img/page-loader.gif') 50% 50% no-repeat rgb(249,249,249); */
    background: rgb(11,19,19);
}

/**
 * Anchors that correct for site header
 */

a.anchor {
    display: block;
    position: relative;
    top: -56px - calc($spacing-unit / 2); /* min. height of site-header class */
    height: 0px;
    visibility: hidden;
}

/**
 * Site header
 */

#sticky {
}
#sticky.stick {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10000;
}

#sticky-phantom{
  visibility: hidden;
}

.site-header {
/*
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    */
    min-height: 56px;

    background-color: black;
    color: $text-color-extra-light;
    a:link{color:#ffef99};
    a:visited{color:#ffef99}
    /* mouse over link */
    a:hover{color:$grey-color-light}
/*
    // Positioning context for the mobile navigation icon
    position: relative;
    */
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-light;
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color-extra-light;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: fixed;
        top: 9px;
        right: 30px;
        background-color: black;
        border: 1px solid $grey-color-dark;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-light;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    /* height: 20%; */
    height: 180px;
    /*border-top: 1px solid $grey-color-light;*/
    /*background-color: $grey-color-dark;*/
    background-color: #000000;
    color: $text-color-light;
    padding: $spacing-unit 0;
    /* */
    a:link{color:#ffef99};      /* This makes the color of links golden */
    a:visited{color:#ffef99}

    min-height: 180px;    
        
    a {
        color: $brand-color-light;
        
        &:hover {
            color: $text-color-light;
        }
    }
    
    @include media-query($on-laptop) {
        min-height: 260px;
    }
    
    @include media-query($on-palm) {
        min-height: 350px;
    }   
}

.footer-heading {
    font-size: 18px;
    color: $text-color-extra-light;
    margin-top: calc($spacing-unit / 2);
    margin-bottom: calc($spacing-unit / 2);
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 14px;
    margin-left: - calc($spacing-unit / 2);
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
}

.footer-col-1 {
    width: -webkit-calc(33% - (#{$spacing-unit} / 2));
    width:         calc(33% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(22% - (#{$spacing-unit} / 2));
    width:         calc(22% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    background: $background-color;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;
    h2 {
        font-weight: bold;
        text-align: left;
        }
    text-align: justify;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
    text-align: left;
}

.post-link {
    display: block;
    font-size: 24px;
}

.post-permalink {
    font-size: $small-font-size;
    color: $grey-color;
    text-align: left;
}


/* **************************************** */
/*      style for the news archive link     */
/* **************************************** */

.news-archive-link{
    color: crimson;
    /*
    font-size: 1.2rem;
    */
    font-size: 1.2rem;
    &:hover {
    text-decoration: none;
    color: Coral;
}
        /* "fas fa-archive"; */
        /*
    &:before {
        font-family: FontAwesome;
        content: "\f187";
        padding-right: .5em;
    }
    */
  -webkit-transition: color 0.2s ease-in-out;
}


/* **************************************** */
/*      style for the RSS Feeds tags        */
/* **************************************** */

.rss-feed-link{
    color: crimson;
    font-size: 1.2rem;
    &:hover {
    text-decoration: none;
    color: Coral;
}
        /* "fas fa-rss"; */
        /*
    &:before {
        font-family: FontAwesome;
        content: "\f09e";
        padding-right: .5em;
    }
        */
  -webkit-transition: color 0.2s ease-in-out;
}

/* **************************************** */
/* Start of the the style for the post tags */
/* **************************************** */

.post-tag {
  display: inline-block;
  /* 
  background: rgba(red, 0.05);
  background: rgba(#6a9fb5, 0.15);
  */
  padding: 0 .5rem;
  margin-right: .5rem;
  border-radius: 4px;
  color: $grey-color;
  /* 
  color: #6a9fb5;
  font-family: $font-sans;
  */
  font-size:90%;
    /*  "fas fa-tag" */
    /* 
  &:before {
    font-family: FontAwesome;
    content: "\f02b"; 
    padding-right: .5em;
  }
    */
  &:hover {
    text-decoration: none;
    /* 
    background: #6a9fb5;
    */
    background: crimson;
    color: white; /* white */
  }
  -webkit-transition: color 0.2s ease-in-out;
    /* 
  @include transition(all .1s ease-in-out);
    */
}

.tags {
  list-style: none;
  margin: 0;
  overflow: hidden; 
  padding: 0;
}

.tags li {
  float: left; 
}

.tag {
  /* background: #eee; */
  background: $background-color;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 10px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.tag::before {
  /* background: #fff; */
  background: $background-color;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.tag::after {
  /* background: #fff; */
  background: $background-color;
  border-bottom: 13px solid transparent;
  /* border-left: 10px solid #eee; */
  border-left: 10px solid $background-color;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.tag:hover {
  background-color: crimson;
  color: white;
}

.tag:hover::after {
   border-left-color: crimson; 
}

/* styles for tag cloud */
.tag-size-5 {
    font-size: 1.4rem;
}
.tag-size-4 {
    font-size: 1.3rem;
}
.tag-size-3 {
    font-size: 1.20rem;
}
.tag-size-2 {
    font-size: 1.10rem;
}
.tag-size-1 {
    font-size: 1.00rem;
}

/* ************************************** */
/* End of the the style for the post tags */
/* ************************************** */

/**
 * Publication list
 */
.pub-list {
    margin-left: 0;
    list-style: none;
    
    h2 {
        text-align: justify;
        }
    
    > li {
        /* margin-bottom: calc($spacing-unit / 2); */
        margin-bottom: calc($spacing-unit / 2);
    }
    
    .pub-year {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: calc($spacing-unit / 10);
        margin-top: calc($spacing-unit / 2);
       
        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}


/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
    font-weight: bold;
    text-align:left;
}

.post-title {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 1;
    text-align:left;
    
    @include media-query($on-laptop) {
        font-size: 36px;
    }
}


.post-content {
    margin-bottom: $spacing-unit;
    text-align: justify;
    
    h2 {
        font-size: 32px;
        text-align: justify;
        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;
        text-align: justify;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;
        text-align: justify;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

/**
 * amir_shahmoradi page
 */

#amir_shahmoradi { 
    background: url(../img/haleakalaSummit_optimized.jpg) no-repeat top left rgb(1,2,2);
    -webkit-background-size: 100%;
    -moz-background-size: 100%;
    -o-background-size: 100%;
    background-size: 100%;
    
    /* @include media-query($on-palm) { */
    @media all and (max-aspect-ratio: 2000/1107) {
        background: url(../img/haleakalaSummit_optimized.jpg) no-repeat top left rgb(1,2,2);
        -webkit-background-size: auto 100%;
        -moz-background-size: auto 100%;
        -o-background-size: auto 100%;
        background-size: auto 100%;
    }
    
    /*
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    */
}

.firstcharacter {
    float: left;
    /* color: #903; */
    /* color: #ffef99; */
    /* font-size: 75px; */
    font-size: 65px;
    /* line-height: 60px; */
    line-height: 60px;
    padding-top: 4px;
    /* padding-right: 8px; */
    padding-right: 20px;
    padding-left: 4px;
    font-family: "Edwardian Script ITC";
    }

/* The following are needed for fluid video embedding aspect ratio */    
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* End of fluid video embedding aspect ratio */

/* The following are needed for fluid three-column equal height layout */    

.container {
    width:100%;
    font-weight:normal;
    text-align:center;
    align-content: center;
    text-justify:newspaper;
    text-kashida-space:50%;
    /* margin-bottom: 20px; */
    display:block;
    vertical-align:top;
    position: relative;
    float: center;
    display: block;
}

.glowingContainer {
    /*
    -webkit-filter: brightness(98%);
    */
    width:100%;
    font-weight:normal;
    text-align:center;
    align-content: center;
    text-justify:newspaper;
    text-kashida-space:50%;
    /* margin-bottom: 20px; */
    background-size: 100% 100%;
    vertical-align:top;
    position: relative;
    float: center;
    display: block;
    transition: 0.2s ease-in-out;
    /*
    */
    transform: translate3d(0,0,0);
}.glowingContainer:hover{-webkit-filter: brightness(110%);}

.three-column {
    width:33.33333333%;
    float:left;
    word-wrap: break-word;
    margin-bottom: 40px;
    display: inline-block;
}

.two-column {
    width:50%;
    float:left;
    word-wrap: break-word;
    margin-bottom: 40px;
    display: inline-block;
}

.center{text-align:center;}

.table {
    text-align:center;
    display:inline-block;
    align-content: center;
    border-spacing: 30px 0;
    border-collapse: separate;
    @include media-query($on-palm) {
      .table {
          /*
          centers contents
          */
          display: inline-block;
          align-content:center;
          align-self: center;
          text-align:center;
          width: 100%;
      }
      .row {
          display: inline-block;
          justify-content: center;
          align-content:center;
          align-self: center;
          text-align:center;
      }
      .col {
          display: inline-block;
          justify-content:center;
          align-content:center;
          text-align:center;
          width:100%;
      }
    }
}
.row {
    text-align:center;
    align-content:center;
    display: table-row;
}
.col {
    padding-bottom:40px;
    text-align:center;
    align-content:center;
    width:33.33333333%;
    display: table-cell;
}

.boxes {
  display:table;
  width: 100%;
  height: 100%;
  @include media-query($on-palm) {
    .box {
          display: block;
          width: 100%;
         }
  }
}

.box {
  align:left;
  display: table-cell;
  text-align: left;
  align-content: left;
  vertical-align: middle;
  float: center;
}


.icon-biomedicine {
  width: 150px;
  height: 150px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/research_tgm.png);
  background-size: 100% 100%;
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 70px;
    height: 70px;
  }
}
.icon-biomedicine:hover {-webkit-filter: brightness(120%);}

.icon-biophysics {
  width: 150px;
  height: 150px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/research_mb.png);
  background-size: 100% 100%;
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 70px;
    height: 70px;
  }
}
.icon-biophysics:hover {-webkit-filter: brightness(120%);}


.icon-astrophysics {
  width: 150px;
  height: 150px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/research_aca.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 70px;
    height: 70px;
  }
}
.icon-astrophysics:hover {-webkit-filter: brightness(120%);}

.icon-statisticalAlgorithms {
  width: 150px;
  height: 150px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/research_mc.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 70px;
    height: 70px;
  }
}
.icon-statisticalAlgorithms:hover {-webkit-filter: brightness(110%);}

.icon-nasa {
  width: 121.21212121px;
  height: 100px;
  margin: auto auto auto auto;
  background: url(../img/logos/server_nasa.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 40.40404040px;
    height: 33.33333333px;
  }
}
.icon-nasa:hover {-webkit-filter: brightness(130%);}

.icon-googles {
  width: 100px;
  height: 100px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/server_googles.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 33.33333333px;
    height: 33.33333333px;
  }
}
.icon-googles:hover {-webkit-filter: brightness(130%);}

.icon-arxiv {
  width: 213.19444444px;
  height: 100px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/server_arxiv.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 71.06481481px;
    height: 33.33333333px;
  }
}
.icon-arxiv:hover {-webkit-filter: brightness(130%);}

.icon-biorxiv {
  width: 287.95180722px;
  height: 100px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/server_bioRxiv.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 95.98393574px;
    height: 33.33333333px;
  }
}
.icon-biorxiv:hover {-webkit-filter: brightness(130%);}

.icon-medrxiv {
  width: 213.19444444px;
  height: 100px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/medrxiv.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 71.06481481px;
    height: 33.33333333px;
  }
}
.icon-medrxiv:hover {-webkit-filter: brightness(130%);}

.icon-pubmed {
  width: 287.95180722px;
  height: 100px;
  margin: 0 auto 0 auto;
  background: url(../img/logos/pubmed.png);
  background-size: 100% 100%;                        
  display: block;
  overflow: hidden;
  transition: 0.25s ease-in-out;
  @include media-query($on-palm) {
    width: 95.98393574px;
    height: 33.33333333px;
  }
}
.icon-pubmed:hover {-webkit-filter: brightness(130%);}


/* Resizes iframe */
.squeezewrap {
    width: 100%;
    height: 450px;
    padding: 0;
    overflow: hidden;
}
.squeezeframe {
     width:  125%;
    height:  125%;
    border: 0;
        -ms-transform: scale(0.8);
       -moz-transform: scale(0.8);
         -o-transform: scale(0.8);
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

/* layout for sign-off np style */

.notepad-author-info {
  padding: 0;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  /*
  background: #f2f2f2; }
  */
  background: #e8e8e8; }
  @media only screen and (min-width: 40.063em) {
    .notepad-author-info {
      padding-top: 2rem;
      padding-bottom: 2rem; } }
  @media only screen and (min-width: 70em) {
    .notepad-author-info {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem; } }
  @media only screen and (min-width: 90em) {
    .notepad-author-info {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }
  .notepad-author-info .notepad-post-author {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    text-align: center; }
    .notepad-author-info .notepad-post-author .author-label {
      display: block;
      font-size: 1rem;
      color: gray;
      font-style: italic; }
    .notepad-author-info .notepad-post-author .author-website {
      font-size: 1rem; }
    .notepad-author-info .notepad-post-author h1 {
      margin: 0;
      padding: 0;
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      text-transform: uppercase; }
    .notepad-author-info .notepad-post-author > p {
      font-size: 1.125rem; }
      .notepad-author-info .notepad-post-author > p a {
        color: #e51843; }
    .notepad-author-info .notepad-post-author .notepad-post-author-avatar {
      width: 12.5rem;
      height: 12.5rem;
      border-radius: 50%;
      float: none;
      margin-right: 0;
      margin-bottom: 1.25rem; }
      @media only screen and (min-width: 40.063em) {
        .notepad-author-info .notepad-post-author .notepad-post-author-avatar {
          margin-right: 3.125rem;
          margin-bottom: 0;
          float: left; } }
    @media only screen and (min-width: 40.063em) {
      .notepad-author-info .notepad-post-author {
        text-align: left; } }

.post-template .notepad-author-info .nprow {
  background-color: black;
  margin-left: auto;
  margin-right: auto; }

/* ******************************************************** */
/* ******************************************************** */

details {
    position: relative;
    display: block;
    margin: 0 auto; width: 100%;
}
summary::-webkit-details-marker {
  display: none;
}
summary:focus {
  outline:none !important;
}

/* ******************************************************** */
/*  Fancy tab: https://codepen.io/RGonyeau/pen/Mvrzxx
/* ******************************************************** */

/*
.container {
  margin-top:30px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro';
  font-weight:700;
}
*/

.fancyTab {
    text-align: center;
    padding:15px 0;
    background-color: #eee;
    box-shadow: 0 0 0 1px #ddd;
    top:15px;    
    transition: top .2s;
}

.fancyTab.active {
    top:0;
    transition:top .2s;
}

.whiteBlock {
    display:none;
}

.fancyTab.active .whiteBlock {
  display:block;
  height:2px;
  bottom:-2px;
  background-color:#fff;
  width:99%;
/*
  position:absolute;
*/
  position:relative;
  z-index:1;
}

.fancyTab a {
    font-family: 'Source Sans Pro';
    font-size:1.65em;
    font-weight:300;
    transition:.2s;
    color:#333;
}

/*
.fancyTab .hidden-xs {
  white-space:nowrap;
}
*/

.fancyTabs {
    border-bottom:2px solid #ddd;
    margin: 15px 0 0;
}

li.fancyTab a {
    padding-top: 15px;
    top:-15px;
    padding-bottom:0;
}

li.fancyTab.active a {
    padding-top: inherit;
}

.fancyTab .fa {
    font-size: 40px;
    width:100%;
    padding: 15px 0 5px;
    color:#666;
}

.fancyTab.active .fa {
    color: #cfb87c;
}

.fancyTab a:focus {
    outline:none;
}

.fancyTabContent {
    border-color: transparent;
    box-shadow: 0 -2px 0 -1px #fff, 0 0 0 1px #ddd;
    padding: 30px 15px 15px;
    position:relative;
    background-color:#fff;
}

.nav-tabs > li.fancyTab.active > a, 
.nav-tabs > li.fancyTab.active > a:focus,
.nav-tabs > li.fancyTab.active > a:hover {
    border-width:0;
}

.nav-tabs > li.fancyTab:hover {
    background-color:#f9f9f9;
    box-shadow: 0 0 0 1px #ddd;
}

.nav-tabs > li.fancyTab.active:hover {
    background-color:#fff;
    box-shadow: 1px 1px 0 1px #fff, 0 0px 0 1px #ddd, -1px 1px 0 0px #ddd inset;
}

.nav-tabs > li.fancyTab:hover a {
    border-color:transparent;
}

.nav.nav-tabs .fancyTab a[data-toggle="tab"] {
    background-color:transparent;
    border-bottom:0;
}

.nav-tabs > li.fancyTab:hover a {
    border-right: 1px solid transparent;
}

.nav-tabs > li.fancyTab > a {
    margin-right:0;
    border-top:0;
    padding-bottom: 30px;
    margin-bottom: -30px;
}

.nav-tabs > li.fancyTab {
    margin-right:0;
    margin-bottom:0;
}

.nav-tabs > li.fancyTab:last-child a {
    border-right: 1px solid transparent;
}

.nav-tabs > li.fancyTab.active:last-child {
    border-right: 0px solid #ddd;
    box-shadow: 0px 2px 0 0px #fff, 0px 0px 0 1px #ddd;
}

.fancyTab:last-child {
    box-shadow: 0 0 0 1px #ddd;
}

.tabs .nav-tabs li.fancyTab.active a {
    box-shadow:none;
    top:0;
}


.fancyTab.active {
    background: #fff;
    box-shadow: 1px 1px 0 1px #fff, 0 0px 0 1px #ddd, -1px 1px 0 0px #ddd inset;
    padding-bottom:30px;
}

.arrow-down {
    display:none;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 22px solid #ddd;
    /*
    position: absolute;
    */
    position: relative;
    top: -1px;
    left: calc(50% - 20px);
}

.arrow-down-inner {
    width: 0;
    height: 0;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 12px solid #fff;
    /*
    position: absolute;
    */
    position: relative;
    top: -22px;
    left: -18px;
}

.fancyTab.active .arrow-down {
    display: block;
}

@media (max-width: 1200px) {
    .fancyTab .fa {
        font-size: 36px;
    }
    .fancyTab .hidden-xs {
        font-size:22px;
    }
}

@media (max-width: 992px) {
    .fancyTab .fa {
        font-size: 33px;
    }
    
    .fancyTab .hidden-xs {
        font-size:18px;
        font-weight:normal;
    }
        
}

@media (max-width: 768px) {
    .fancyTab > a {
        font-size:18px;
    }
    .nav > li.fancyTab > a {
    padding:15px 0;
    margin-bottom:inherit;
    }
    
    .fancyTab .fa {
        font-size:30px;
    }
    
    .nav-tabs > li.fancyTab > a {
        border-right:1px solid transparent;
        padding-bottom:0;
    }

    .fancyTab.active .fa {
        color: #333;
    }
}

/* ******************************************************** */
/*  End of Fancy tab 
/* ******************************************************** */

/* ******************************************************** */
/*  People tab: https://codepen.io/flkt-crnpio/pen/WxROwy/
/* ******************************************************** */

/*
body {
  background: #191828;
  color: #efedef;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.6em;
  margin: 0;
  padding: 100px; }
h1 {
  font-size: 40px;
  line-height: 0.8em;
  color: rgba(255,255,255,0.2);}
a {
    background: #fd264f;
    color: #fff;
    display: block;
    font-size: 12px;
    line-height: 1em;
    margin: 0;
    padding: 5px 110px;
    position: fixed;
    top: 20px;
    right: -100px;
    text-align: center;
    text-decoration: none;
    transform: rotate(45deg);
}
*/
button:focus,
input:focus,
textarea:focus,
select:focus {
  outline: none; }

.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden; }
  .tabs [class^="tab"] label,
  .tabs [class*=" tab"] label {
    /*
    color: #efedef;
    color: $grey-color-dark;
    */
    color: #cfb87c;
    cursor: pointer;
    display: block;
    font-size: 1.65em;
    font-weight: 300;
    line-height: 1em;
    padding: 2rem 0;
    text-align: center; }
  .tabs [class^="tab"] [type="radio"],
  .tabs [class*=" tab"] [type="radio"] {
    border-bottom: 1px solid rgba(239, 237, 239, 0.5);
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    .tabs [class^="tab"] [type="radio"]:hover,
    .tabs [class^="tab"] [type="radio"]:focus,
    .tabs [class*=" tab"] [type="radio"]:hover,
    .tabs [class*=" tab"] [type="radio"]:focus {
      /*
      border-bottom: 1px solid #fd264f;
      */
      border-bottom: 1px solid $grey-color-dark;
      }
    .tabs [class^="tab"] [type="radio"]:checked,
    .tabs [class*=" tab"] [type="radio"]:checked {
      /*
      border-bottom: 2px solid #fd264f;
      */
      border-bottom: 2px solid $grey-color-dark;
      }
    .tabs [class^="tab"] [type="radio"]:checked + div,
    .tabs [class*=" tab"] [type="radio"]:checked + div {
      opacity: 1; }
    .tabs [class^="tab"] [type="radio"] + div,
    .tabs [class*=" tab"] [type="radio"] + div {
      display: block;
      opacity: 0;
      padding: 2rem 0;
      width: 90%;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
  .tabs .tab-2 {
    width: 50%; }
    .tabs .tab-2 [type="radio"] + div {
      width: 200%;
      margin-left: 200%; }
    .tabs .tab-2 [type="radio"]:checked + div {
      position: relative; /* essential for the checked tab to be on top of the unchecked element */
      margin-left: 0; }
    .tabs .tab-2:last-child [type="radio"] + div {
      margin-left: 100%; }
    .tabs .tab-2:last-child [type="radio"]:checked + div {
      margin-left: -100%; }

.tabs .fa
{
    font-size: 40px;
    width:100%;
    padding: 15px 0 15px;
    color: #cfb87c;
}

.tabs .fas
{
    font-size: 40px;
    width:100%;
    padding: 15px 0 15px;
    color: #cfb87c;
}

.catgory{
    color: #cfb87c;
    cursor: pointer;
    font-size: 1.65em;
    font-weight: 300;
    line-height: 1em;
    text-align: center;
    overflow: visible;
    text-align: center;
    margin-bottom:1rem;
    /*
    border-bottom: 3px solid #cfb87c;
    */
}
.catgory .fa {
    font-size: 40px;
    width:100%;
    padding: 15px 0 15px;
    color: #cfb87c;
}
.catgory .fas {
    font-size: 40px;
    width:100%;
    padding: 15px 0 15px;
    color: #cfb87c;
}
.catgory a{color: #cfb87c;}
.catgory hr {
    overflow: visible; /* For IE */
    height: 30px;
    border-style: solid;
    border-color: #cfb87c;
    border-width: 1.5px 0 0 0;
    border-radius: 20px;
}
.catgory hr:before { /* Not really supposed to work, but does */
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: #cfb87c;
    border-width: 0 0 1.5px 0;
    border-radius: 20px;
}
.anchor a{
    top: -50px;
    display: block;
    position: relative;
    visibility: hidden;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.categoryList{margin-bottom:4rem;}


/*
[type=radio]:checked ~ label {
  background: white;
  border-bottom: 1px solid white;
  z-index: 2;
}
[type=radio]:checked ~ label ~ .content {
  z-index: 1;
}
.fas
{
    font-size: 30px;
    width:100%;
    padding: 15px 0 15px;
    color: white;
}

    color:#666;
.tabs.active .fa
 {
    color: #cfb87c;
}
*/

/*******************************************/
/* mul
/*******************************************/

.doubleFigRow {
    display: flex;
    text-align:center;
    align-content:center;
    margin-bottom: 0px;
}

.doubleFigCol {
  flex: 33.33%;
  padding: 5px;
}

.social-icon {
    padding:0rem 0rem 0.6rem 0.6rem;
    transition: 0.5s ease-in-out;
}

.social-icons a{color: black;}
.social-icons {
    text-align: center;
    float: none;
    /*
    padding-bottom: 1.75rem;
    */
}

/* image brightening on hover */
.blinker{
    transition: 0.25s ease-in-out;
    @include media-query($on-palm) {
        width: 50px;
        height: 50px;
    }
}.blinker:hover{-webkit-filter: brightness(130%);}
